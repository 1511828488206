import React, { useState } from 'react';
import { Container, Grid, Button, Hidden, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './NewPassword.styles';
import ReactCodeInput from 'react-code-input';
import TransitionAlert from '../Common/Alert';
import APIAuth from '../../services/ApiAuth.service';
import { Helmet } from 'react-helmet';
import NavBarV2 from '../Header/NavBarV2';
import { navigate } from 'gatsby';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Modal from '@material-ui/core/Modal';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Contraseña es requerida')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/,
      'Contraseña debe tener mínimo 8 caracteres y al menos 1 letra y 1 número.'
    ),
  passwordConfirmation: yup
    .string()
    .required('Contraseña es requerida')
    .oneOf([yup.ref('password'), null], 'Las contraseñas son distintas.'),
});
const sleep = (time: number) => new Promise((acc) => setTimeout(acc, time));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const NewPassword = (props: any) => {
  const [submit, setSubmit] = React.useState<boolean>(false);
  const classes = useStyles();
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [pinCode, setPinCode] = useState('');
  const [modalStyle] = React.useState(getModalStyle);
  const [btnIsPressed, setBtnIsPressed] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(true);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Actualización exitosa</h2>
      <p id="simple-modal-description">Has actualizado tu contraseña, redirigiendo</p>
    </div>
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      var data = {
        email: props.email,
        resetCode: pinCode,
        newPassword: formik.values.password,
        newPasswordConfirmation: formik.values.passwordConfirmation,
      };
      setSubmit(true);
      await APIAuth.post('/auth/reset-password', data)
        .then((response) => {
          if (response) {
            if (typeof window !== `undefined`) {
              navigate(`${process.env.GATSBY_URL_NEW_FRONT}/login`, { state: { values } });
            }
          } else {
            setSubmit(false);
            setAlertOpen(true);
            setAlertTitle('Error al crear nueva contraseña.');
          }
        })
        .catch((error) => {
          setSubmit(false);
          setAlertOpen(true);
          setAlertTitle('Error al crear nueva contraseña.');
        });
    },
  });

  return (
    <>
      <section id="registerPage" className={classes.root}>
        <NavBarV2 />
        <Helmet bodyAttributes={{ class: 'registerPage' }} />
        <Container className={classes.container} maxWidth={false}>
          <Hidden smDown>
            <Grid item={true} md={6} className={classes.feather} />
          </Hidden>
          <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
          <Grid item={true} className={classes.formGroup} xs={12} md={6} lg={4}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item={true} className={classes.formVerification}>
                <Typography component="h1" variant="h5">
                  Actualización de contraseña
                </Typography>
                <Grid item xs={12}>
                  {/* {!isPinCodeValid && btnIsPressed && ( */}
                  <TransitionAlert
                    setAlertOpen={setAlertOpen}
                    open={alertOpen}
                    title={alertTitle}
                    description="Intente nuevamente"
                    severity="error"
                  />
                  {/* )} */}
                </Grid>
                <p>
                  Por favor ingresa los <b>6 digitos</b> de verificación que fueron enviados a tu
                  correo electrónico.
                </p>{' '}
                <Grid item={true} className={classes.inputGroup} xs={12}>
                  <ReactCodeInput
                    id="pinCode"
                    type="number"
                    isValid={isPinCodeValid}
                    fields={6}
                    onChange={handlePinChange}
                    value={pinCode}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputPassword}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Nueva contraseña"
                    type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                    value={formik.values.password}
                    variant="outlined"
                    onChange={formik.handleChange}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  {/* <FormHelperText>Mínimo 8 caracteres, al menos 1 letra y 1 número.</FormHelperText> */}
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputPassword}>
                  <TextField
                    fullWidth
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    label="Confirmar contraseña"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.passwordConfirmation}
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.passwordConfirmation &&
                      Boolean(formik.errors.passwordConfirmation)
                    }
                    helperText={
                      formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
                    }
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid className={classes.resend} item xs={12}>
                  ¿No recibiste ningún código?
                  <br />
                  <a href="/login/forgot">Enviar código nuevamente</a>
                  <br />
                </Grid>
              </Grid>
              <Grid item className={classes.submitGroup} xs={8} md={8} lg={8}>
                <Button
                  fullWidth
                  startIcon={submit ? <CircularProgress size="1rem" /> : null}
                  disabled={submit}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Actualizar
                </Button>
              </Grid>
            </form>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default NewPassword;
