import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../images/Registration/pluma.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      backgroundColor: `#FFF`,
      height: 800,
      '& #codeInput': {
        marginBottom: `60px`,
        marginTop: `23px`,
      },
      [theme.breakpoints.down(501)]: {
        height: 700,
      },
      '& #password': {
        [theme.breakpoints.down(769)]: {
          marginbottom: 200,
        },
      },
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    inputPassword: {
      marginBottom: 30,
      [theme.breakpoints.down(769)]: {
        marginLeft: '5%',
        marginRight: '5%',
      },
    },
    container: {
      display: 'block',
      color: '#004F71',
      height: 700,
      paddingLeft: `0%`,
      paddingRight: `0%`,
      paddingTop: `0%`,
      paddingBottom: `0%`,
      [theme.breakpoints.up(768)]: {
        height: 800,
      },
      '& h1': {
        fontSize: 32,
        [theme.breakpoints.down(501)]: {
          fontSize: 30,
          padding: `0px 20px`,
          lineHeight: `35px`,
        },
      },
      '& #password-helper-text': {
        margin: `5px 0px 0px 5px`,
        padding: 0,
      },
      '& #passwordConfirmation-helper-text': {
        margin: `5px 0px 0px 5px`,
        padding: 0,
      },
      '& p': {
        textIndent: `6px`,
        lineHeight: `18px`,
        fontSize: `16px`,
        marginTop: `26px`,
        padding: `0px 35px 0px 25px`,
        [theme.breakpoints.down(1441)]: {
          fontSize: 14,
          padding: `0px 0px 0px 0px`,
          textIndent: `0px`,
        },
        [theme.breakpoints.down(1364)]: {
          fontSize: 14,
          padding: `0px 65px 0px 65px`,
        },
        [theme.breakpoints.down(1100)]: {
          fontSize: 14,
          padding: `0px 40px 0px 40px`,
        },
        [theme.breakpoints.down(501)]: {
          fontSize: 14,
          padding: `0px 38px 0px 15px`,
          textIndent: `23px`,
        },
      },
      '& b': {
        fontWeight: `600`,
      },
      '& h2': {
        [theme.breakpoints.down(501)]: {
          fontSize: 18,
        },
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `3%`,
        paddingRight: `3%`,
      },
    },
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonContainer: {
      paddingLeft: '20%',
    },
    input: {
      marginRight: 3,
      '& input': {
        height: 10,
      },
    },
    reactCodeInput: {
      padding: 200,
    },
    card: {
      width: `100%`,
    },
    inputGroup: {
      marginTop: 20,
      marginBottom: 20,
      '& input': {
        width: `56px !important`,
        height: `56px !important`,
        fontFamily: `Montserrat !important`,
        color: `#004F71 !important`,
        paddingLeft: `13px !important`,
        margin: `5px !important`,
        [theme.breakpoints.down(1441)]: {
          width: `50px !important`,
          height: `50px !important`,
        },
        [theme.breakpoints.down(1000)]: {
          margin: `2px !important`,
          width: `45px !important`,
          height: `45px !important`,
          fontSize: `28px !important`,
        },
        [theme.breakpoints.down(501)]: {
          width: `40px !important`,
          height: `40px !important`,
          fontSize: `24px !important`,
          paddingLeft: `12px !important`,
        },
      },
    },
    formVerification: {
      paddingLeft: '10%',
      paddingRight: '10%',
      textAlign: `center`,
      [theme.breakpoints.down(1000)]: {
        paddingLeft: '5%',
        paddingRight: '5%',
      },
    },
    formGroup: {
      paddingTop: 55,
      float: `left`,
      width: `100%`,
      height: `100%`,
    },
    feather: {
      float: `left`,
      width: `100%`,
      height: `100%`,
      backgroundImage: `url(${background})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `right`,
      backgroundSize: `cover`,
      // backgroundColor:`#FF3`,
    },
    submitGroup: {
      margin: `auto`,
      '& button': {
        borderRadius: `30px`,
        backgroundColor: `#F8B133`,
        height: 48,
        textTransform: `capitalize`,
        marginBottom: 58,
        '&:hover': {
          background: '#074F71',
          color: `#FAB234`,
          border: '1px solid #FAB234',
        },
      },
    },
    resend: {
      '& a': {
        color: `#F8B133`,
        fontWeight: 600,
      },
      marginBottom: 20,
      [theme.breakpoints.down(501)]: {
        fontSize: 14,
      },
    },
  })
);
export default useStyles;
